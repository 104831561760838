<template>
  <div class="app-item app-content">
    <h4>商家入驻</h4>
    <el-steps
      :active="status+2"
      finish-status="success"
      simple
      style="margin: 20px 0;border-radius:25px"
    >
      <el-step title="提交资料"></el-step>
      <el-step title="等待审核"></el-step>
      <el-step title="入驻成功"></el-step>
    </el-steps>
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" v-if="status===-2||status>=1">
      <el-form-item label="商家名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入商家名称" :disabled="disabled"></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="id_number">
        <el-input v-model="ruleForm.extension.id_number" placeholder="请输入您的身份证号码" :disabled="disabled"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="请输入您的电话号码" :disabled="disabled"></el-input>
      </el-form-item>
      <el-form-item label="营业执照号" prop="license">
        <el-input v-model="ruleForm.extension.business_licence_number" placeholder="请输入营业执照号" :disabled="disabled"></el-input>
      </el-form-item>
      <el-form-item label="身份表示" prop="resource">
        <el-radio-group v-model="ruleForm.identity" :disabled="disabled">
          <el-radio v-model="ruleForm.identity" label="个人"></el-radio>
          <el-radio v-model="ruleForm.identity" label="公司"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="营业电子执照" prop="licenseImg" class="upload-img" v-if='ruleForm.identity!=="个人"'>
        <el-upload
          class="avatar-uploader"
          action
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :disabled="disabled"
        >
          <div class="upload-img">
            <img v-if="ruleForm.extension.business_licence_electronic" :src="ruleForm.extension.business_licence_electronic" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="身份证正反照">
        <div class="flex">
          <div class="content">
            <el-upload
              class="avatar-uploader identity"
              action
              :show-file-list="false"
              :http-request="fnUploadRequest1"
              :disabled="disabled"
            >
              <div class="upload-img">
                <img v-if="ruleForm.extension.id_front" :src="ruleForm.extension.id_front" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
            <div class="tips">正面照</div>
          </div>
          <div class="content">
            <el-upload
              class="avatar-uploader identity"
              action
              :show-file-list="false"
              :http-request="fnUploadRequest2"
              :disabled="disabled"
            >
              <div class="upload-img">
                <img v-if="ruleForm.extension.id_back" :src="ruleForm.extension.id_back" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
            <div class="tips">反面照</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm()" :disabled="disabled">立即创建</el-button>
      </el-form-item>
    </el-form>
    <div v-else-if="status===-1" class="prompt">商家资料提交成功，等待审核中。。。</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      disabled: false,
      status: null,
      imageUrl: '',
      id_front_url: '',
      id_back_url: '',
      ruleForm: {
        identity: '个人',
        name: '',
        id_number: '',
        mobile: '',
        license: '',
        path: '',
        licenseImg: '',
        delivery: false,
        id_front: '',
        id_back: '',
        extension: {
          id_number: '',
          business_licence_number: '',
          business_licence_electronic: '',
          id_back: '',
          id_front: ''
        }
      }
    }
  },
  methods: {
    // 开店成功提示
    getMark () {
      this.$store.dispatch('getMark').then(res => {
        console.log(res)
      })
    },
    // 上传营业执照
    beforeAvatarUpload (file) {
      let fd = new FormData()
      fd.append('img', file)
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      } else if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      } else {
        this.$store.dispatch('uploadImg', fd).then(res => {
          this.ruleForm.extension.business_licence_electronic = res.data.url
          this.ruleForm.path = res.data.path
        })
      }
      return isJPG && isLt2M
    },
    // 身份证正反照
    fnUploadRequest1 (option) {
      let fd = new FormData()
      fd.append('img', option.file)
      this.$store.dispatch('uploadImg', fd).then(res => {
        this.ruleForm.extension.id_front = res.data.url
        this.ruleForm.id_front = res.data.path
      })
    },
    fnUploadRequest2 (option) {
      let fd = new FormData()
      fd.append('img', option.file)
      this.$store.dispatch('uploadImg', fd).then(res => {
        this.ruleForm.extension.id_back = res.data.url
        this.ruleForm.id_back = res.data.path
      })
    },
    // 提交信息
    submitForm () {
      if (this.ruleForm.identity === '个人') {
        this.ruleForm.identity = 1
      } else if (this.ruleForm.identity === '公司') {
        this.ruleForm.identity = 2
      }
      // this.change(this.ruleForm.identity)
      let obj = {
        name: this.ruleForm.name,
        mobile: this.ruleForm.mobile,
        identity: this.ruleForm.identity,
        id_number: this.ruleForm.extension.id_number,
        business_licence_number: this.ruleForm.extension.business_licence_number,
        business_licence_electronic: this.ruleForm.path,
        id_front: this.ruleForm.id_front,
        id_back: this.ruleForm.id_back
      }
      this.$store.dispatch('tenants', obj).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示信息',
            message: '商家资料提交成功，等待审核中。。。  ',
            type: 'warning'
          })
          this.status = -1
        } else {
          this.$notify({
            title: '提示信息',
            message: res.message,
            type: 'warning'
          })
        }
      })
    }
  },
  mounted () {
    // 审核情况
    this.$store.dispatch('getInfo').then(res => {
      // console.log('审核：', res)
      if (res.info.store !== null) {
        this.status = res.info.store.status
        //  审核失败/平台关闭
        if (res.info.store.status < 0) {
          this.$notify({
            title: '提示信息',
            message: res.info.store.status_desc,
            type: 'warning'
          })
          this.status = -2
        //  等待审核
        } else if (res.info.store.status === 0) {
          this.disabled = !this.disabled
          this.status = -1
          this.$notify({
            title: '提示信息',
            message: '等待审核，请勿再次提交',
            type: 'warning'
          })
        } else if (res.info.store.status >= 1) {
          this.ruleForm = res.info.store
          this.disabled = !this.disabled
          this.ruleForm.identity = res.info.store.identity
          if (res.info.store.identity === 1) {
            this.ruleForm.identity = '个人'
          } else if (res.info.store.identity === 2) {
            this.ruleForm.identity = '公司'
          }
          if (res.info.store.is_show === 0) {
            this.$message({
              message: '审核通过,入驻成功',
              type: 'success'
            })
            this.getMark()
          }
        }
      } else {
        this.status = -2
      }
    })
  }
}
</script>
<style lang="less" scoped>
.tips {
  width: 100px;
  text-align: center;
}
.flex {
  justify-content: normal;
  .content {
    flex-direction: column;
    justify-content: center;
  }
}
.identity {
  display: inline-block;
  margin-right: 2rem;
  overflow: hidden;
}
.prompt{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:150px;
  color:#67C23A;
  font-size:18px

}
@media screen and (max-width: 700px) {
  .app-item {
    .identity {
      margin-right: 1rem;
    }
  }
}
</style>
